import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Marketing1 from "../assets/images/Marketing/1.webp";
import Marketing2 from "../assets/images/Marketing/2.webp";
import Marketing3 from "../assets/images/Marketing/3.webp";
import Marketing4 from "../assets/images/Marketing/4.webp";
import Footer1 from "../assets/images/Home/7.webp";




const Marketing = () => (
    <Layout>
        <SEO title="Marketing" />
        <Navbar />
        <div className="page-title-area">
            <img className="imageStyle" src={Marketing1} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Marketing2} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Marketing3} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Marketing4} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Footer1} alt="image" />
        </div>
    </Layout>
)

export default Marketing;
